<template>
  <div>
    <h3 class="page-title">微信商户参数管理</h3>
    <el-divider></el-divider>
    <el-row>
      <el-button type="primary" plain icon="el-icon-circle-plus-outline" v-if="pageTags.create" @click="add">添加 </el-button>
    </el-row>
    <!-- table-box -->
    <div class="table-box" v-if="pageTags.list">
      <el-table
        :data="tableData" 
        border 
        style="width: 100%" 
        v-loading="loading">
        <el-table-column prop="merchant_id" label="商户号"></el-table-column>
        <el-table-column prop="mp_appid" label="公众号 appid"></el-table-column>
        <el-table-column prop="mp_appsecret" label="公众号 mp_appsecret"></el-table-column>
        <el-table-column prop="mini_appid" label="小程序 mini_appid"></el-table-column>
        <el-table-column prop="mini_appsecret" label="小程序 mini_appsecret"></el-table-column>
        <el-table-column prop="pay_key" label="支付密钥"></el-table-column>
        <el-table-column label="操作" prop="dosome" width="300">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              v-if="pageTags.update"
              @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              v-if="pageTags.delete"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table> 
    </div>

    <!-- el-dialog -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="35%"
      :before-close="handleClose">
      <div class="form-content">
        <el-form label-width="120px" :model="ruleForm" ref="ruleForm">
          <el-form-item label="商户号" prop="merchant_id">
            <el-input v-model="ruleForm.merchant_id" placeholder="商户号"></el-input>
          </el-form-item>
          <el-form-item label="mp_appid" prop="mp_appid">
            <el-input v-model="ruleForm.mp_appid" placeholder="mp_appid"></el-input>
          </el-form-item>
          <el-form-item label="mp_appsecret" prop="mp_appsecret">
            <el-input v-model="ruleForm.mp_appsecret" placeholder="mp_appsecret"></el-input>
          </el-form-item>
          <el-form-item label="mini_appid" prop="mini_appid">
            <el-input v-model="ruleForm.mini_appid" placeholder="mini_appid"></el-input>
          </el-form-item>
          <el-form-item label="mini_appsecret" prop="mini_appsecret">
            <el-input v-model="ruleForm.mini_appsecret" placeholder="mini_appsecret"></el-input>
          </el-form-item>
          <el-form-item label="支付密钥" prop="pay_key">
            <el-input v-model="ruleForm.pay_key" placeholder="pay_key"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="submitForm('ruleForm')">确定</el-button>
        <el-button type="warning" plain @click="resetForm('ruleForm')">重置</el-button>
        <el-button type="danger" plain @click="closeDialog('ruleForm')">取消</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  var self;
  export default {
    activated: function() {
      self = this;
      self.getList();
    },
    data() {
      return {
        tableData: [],
        loading: false,
        dialogVisible: false,
        ruleForm: {
          merchant_id:"",     //商户号
          mp_appid:"",        //公众号 appid
          mp_appsecret:"",    //公众号 appsecret
          mini_appid:"",      //小程序 appid
          mini_appsecret:"",  //小程序 appsecret
          pay_key:""          //支付密钥
        },
        dialogTitle:"",
        addDialog:true,
        editId:"",
        pageTags:{}
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self.$fetch("admin/merchants").then(response => {
          if (response.status == 200) {
           self.tableData = response.data.data;
          } else{
            console.log(response.message);
          }

          self.loading = false;
          if(JSON.parse(sessionStorage.getItem("curTags"))){
            self.pageTags = JSON.parse(sessionStorage.getItem("curTags"));
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.loading = false;
        });
      },
      add() {
        self.addDialog = true;
        self.dialogTitle = "添加";
        self.dialogVisible = true;
      },
      handleEdit(index, row) {
        self.addDialog = false;
        self.dialogTitle = "编辑";
        self.editId = row.id;
        self.ruleForm.merchant_id = row.merchant_id;
        self.ruleForm.mp_appid = row.mp_appid;
        self.ruleForm.mp_appsecret = row.mp_appsecret;
        self.ruleForm.mini_appid = row.mini_appid;
        self.ruleForm.mini_appsecret = row.mini_appsecret;
        self.ruleForm.pay_key = row.pay_key;
        self.dialogVisible = true;
      },
      handleDelete(index, row) {
        self.$confirm("确认删除吗？", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          self.$remove('admin/merchants/'+row.id).then((response) => {
            console.log(response);
            if(response.status == 204){
              self.getList();
              self.$message({
                message: "成功删除",
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response);
            }
          }).catch(err => {
            console.log('××××××××××××××××');
            console.log(err);
          });

        }).catch(() => {
          self.$message({
            type: "info",
            message: "取消删除"
          });
        });
      },
      submitForm(formName) {
        var myData = {
          merchant_id:self.ruleForm.merchant_id,
          mp_appid:self.ruleForm.mp_appid,
          mp_appsecret:self.ruleForm.mp_appsecret,
          mini_appid:self.ruleForm.mini_appid,
          mini_appsecret:self.ruleForm.mini_appsecret,
          pay_key:self.ruleForm.pay_key
        }

        if(self.addDialog){
          self.$post('admin/merchants',myData).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response.data.message);
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }else{
          self.$put('admin/merchants/'+self.editId,myData).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              self.$message({
                message: response.data.message,
                type: "warning",
                duration: 1500
              });
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }
      },
      resetForm(formName) {
        self.$refs[formName].resetFields();
        self.ruleForm = {
          merchant_id:"",
          mp_appid:"",
          mp_appsecret:"",
          mini_appid:"",
          mini_appsecret:"",
          pay_key:""
        }
      },
      closeDialog(formName) {
        self.resetForm(formName);
        self.dialogVisible = false;
      },
      handleClose(done) {
        done();
        self.resetForm("ruleForm");
      }
    }
  };
</script>
